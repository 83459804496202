@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

:root{
    --bg-color: #A91079;
    --bg-black-color: #010101;
    --alt-bg-color: #4e084e;
    --alt-bg-color-2: #e5e7eb;
    --text-color: #f8f8ff;
    --shade-clor: #A91079;
    --text-color: #790252
}  

body{
  background-color: var(--alt-bg-color-2);
  color: white;
  overflow-x: hidden;
  /* font-family: 'Inter'; */
}

.container-box {
  width: 80%;
  background-color: transparent;
  position: relative;
  z-index: 9;
  user-select: none;
}

.midBannerContainer {
  /* background: rgb(2,0,36); */
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(26, 2, 23, 0.98)15%, rgba(34, 3, 31, 0.98) 33%, rgba(53,4,48,0.98) 67%, rgba(93,7,66,1) 100%); */
  background: linear-gradient(90deg, #350430 0%, #5d0742 100%);
  width: 100%;
}

.subscribeContainer {
  background: linear-gradient(135deg, #e0cfe8 0%, #d6e7cf 100%);
  width: 100%;
}

.pricing {
  width: 100%;
}

.contactusContainer {
  width: 100%;
  background: linear-gradient(135deg, #ecddf4 0%, #e2eedd 100%);
}

@media only screen and (max-width: 600px) {
  .subscribeContainer {
    height: 55rem;
  }
}