.midBanner {
	margin-top: 2.5rem;
	height: 100vh;
}

.midBannerSuperText {
	margin-top: 22.5vh;
	text-align: center;
}

.midBannerSuperText > h2 {
	opacity: 1;
	font-size: 3rem;
}

.midBannerText {
	margin-top: 1.5rem;	
}

.midBannerText > h5{
	font-size: 1.5rem;
	line-height: 2.5rem;
	text-align: center;
	color: #838383;
}

.midBannerButtonComponent{
	margin-top: 1rem;
}

.getStartedButton{
    /* background-image: linear-gradient(to right, #809ca7 0%, #213293 51%, #809ca7 100%); */
    background-image: linear-gradient(to right, #40E0D0 0%, #4d1a88 51%, #40E0D0 100%);
    border-radius: 10px;
    border-color: var(--alt-bg-color);
    transition: 0.5s;
    background-size: 200% auto;
    height: 3.5rem;
    width: 12rem;
    margin-top: 1.4rem;
    font-size: 1.15rem;
    margin-left: 10px;
}

.getStartedButton:hover{
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    border-color: var(--alt-bg-color);
    box-shadow: 0 0 10px var(--alt-bg-color);
}

.learnMoreButton {
    border-radius: 10px;
    border-width: 0.1rem;
    border-color: #4d1a88;
    background-color: transparent;
    transition: 0.5s;
    background-size: 200% auto;
    height: 3.5rem;
    width: 12rem;
    margin-top: 1.4rem;
    font-size: 1.15rem;
    margin-left: 1.5rem;
}

.learnMoreButton:hover{
    background-image: linear-gradient(to right, #40E0D0 0%, #4d1a88 51%, #40E0D0 100%);
    background-image: linear-gradient(to right, #40E0D0 0%, #4d1a88 51%, #40E0D0 100%);
    color: #fff;
    text-decoration: none;
    border-color: var(--alt-bg-color);
    box-shadow: 0 0 10px var(--alt-bg-color);
}

.midBannerButtonComponent{
	position: absolute;
	text-align: center;
}

@media only screen and (max-width: 600px) {
	.midBanner {
		height: 100vh;
	}

	.midBannerSuperText {
		margin-top: 8rem;
	}

	.midBannerSuperText > h2 {
		font-size: 2rem;
		margin-top: 0.2rem;
	}

	.midBannerText > h5 {
		/* width: 25rem; */
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.getStartedButton {
		margin-top: 5rem;
		height: 4rem;
		width: 15rem;
		font-size: 1.5rem;
	}

	.learnMoreButton {
		margin-left: 0.5rem;
		height: 4rem;
		width: 15rem;
		font-size: 1.5rem;
	}

	.midBannerButtonComponent{
		margin-top: 0rem;
	}
}