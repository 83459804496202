.pricing {
	text-align: center;
}

.contentCenter {
	display: flex;
	justify-content: center;
}

.pricingSuperHeader > h2 {
	color: var(--text-color);
	font-size: 2rem;
}

.pricingHeader > h2 {
	color: var(--bg-black-color);
	font-size: 3.2rem;
	margin-top: 0.5rem;
}

.pricingTextMessage {
	/* margin: 1rem 0; */
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.pricingTextMessage > h5 {
	text-align: center;
	font-weight: 500;
	line-height: 2.5rem;
	font-size: 1.3rem;
	color: rgb(130, 135, 141);
}

.pricingFooterText {
	margin-top: 1rem;
}

.pricingFooterText > h5 {
	text-align: center;
	font-weight: 500;
	font-size: 1.1rem;
	color: rgb(130, 135, 141);
}

.pricingTableCard{
	/* background: transparent; */
	display: flex;;
	justify-content: center;
	border-radius: 1rem;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.tableDataHeaderCell{
	color: var(--alt-bg-color);
	font-size: 1.1rem;
	background-color: rgb(219, 211, 211);
}

.pricingRow{
	justify-content: center;
}

.pricingTable{
	margin-bottom: 0;
}

.tierTableHeader {
	width: 5rem;
}

@media only screen and (max-width: 600px) {
	.pricingSuperHeader {
		margin-top: 7rem;
	}

	.pricingHeader > h2 {
		font-size: 2.2rem;
		text-align: center;
	}

	.pricingTextMessage > h5 {
		font-size: 1.2rem;
		line-height: 2rem;
	}

	.pricingFooterText > h5 {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}