.logo {
    width: 25%;
}

.name{
  font-size: 1.8rem;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}

.nav-theme {
  font-size: 20px;
  background-color: var(--bg-black-color);
  /* box-shadow: 1px 2px #888888; */
}

.nav-link{
  text-decoration: none !important;
  color: white !important;
  cursor: pointer;
  user-select: none;
}

.animate-navbar {
  box-shadow: 0.1px 0.1px 6px var(--shade-clor);
  animation: moveDown 0.5s ease-in-out;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}