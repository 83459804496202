.about {
	margin-top: 2.5rem;
	height: 25rem;
}

.contentCenter{
	display: flex;
	justify-content: center;
}

.aboutSuperHeaderText{
	color: var(--text-color);
	font-size: 2rem;
}

.aboutHeaderText{
	color: var(--bg-black-color);
	font-size: 3.2rem;
	margin-top: 0.25rem;
}

.aboutText{
	margin-top: 2rem;
	text-align: center;
	font-weight: 500;
	line-height: 2.2rem;
	font-size: 1.35rem;
	color: rgb(130, 135, 141);
}

@media only screen and (max-width: 600px) {
	.aboutHeaderText {
		font-size: 1.8rem;
		text-align: center;
	}

	.aboutText{
		font-size: 1.05rem;
		margin-top: 0.2rem;
	}
}