.subscribe {
	text-align:  center;
	height: 100vh;
}

.subscribeHeaderText {
	margin-top: 22vh;
}

.subscribeHeaderText > h2 {
	color: var(--alt-bg-color);
	font-size: 3.2rem;
	margin-top: 0.5rem;
}

.subscribeText {
	margin-top: 1rem;
}

.subscribeText > h5 {
	text-align: center;
	font-weight: 500;
	line-height: 2.5rem;
	font-size: 1.3rem;
	color: rgb(130, 135, 141);
}

.subscribeInput {
	height: 3.5rem;
	border-radius: 0.5rem;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.subscribeBtnRow {
	margin-top: 2rem;
}

.subscribeNotifyButton {
	display: flex;
	justify-content: flex-start;
	background-image: linear-gradient(to right, var(--bg-color) 0%, var(--alt-bg-color) 51%, var(--bg-color) 100%);
    border-radius: 10px;
    border-color: var(--alt-bg-color);
    transition: 0.5s;
    background-size: 200% auto;
    height: 3.4rem;
    width: 10rem;
    font-size: 1.3rem;

	padding-left: 2rem;
	padding-top: 0.5rem;

	box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.subscribeNotifyButton:hover{
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    border-color: var(--alt-bg-color);
    /* box-shadow: 0 0 10px var(--alt-bg-color); */
}

@media only screen and (max-width: 600px) { 
	.subscribeHeaderText{
		margin-top: 7rem;
	}

	.subscribeHeaderText > h2 {
		font-size: 2.7rem
	}

	.subscribeBtnRow {
		/* display: flex; */
		justify-content: center;		
	}

	.subscribeNotifyButton {
		margin-top: 1rem;
	}

	.subscribeBtnRowCol1 {
		display: flex;
		justify-content: center;		
		margin-bottom: 10rem;
	}
}