.contactus {
	align-items: center;
	text-align: center;
	height: 100vh;
}

.contactusHeader {
	align-items: center;
	margin-top: 17vh;
}

.contactusHeader > h2 {
	color: var(--alt-bg-color);
	font-size: 3.2rem;
}

.contactUsCardRow {
	margin-top: 1.5rem;
}

.contactusFormCard {
	height: 26.5rem;
	padding: 2.5rem 2rem 0 2rem;
	border-radius: 0.4rem;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.contactusText {
	text-align: left;
}

.contactusText > p {
	color: #838383;
	font-size: 1rem;
}

.dropMeLine {
	text-align: left;
}

.dropMeLine > h2 {
	color: var(--alt-bg-color);
	font-size: 1.6rem;
}

.queryArea {
	margin-top: 1.5rem;
	height: 8rem !important;
}

.subscribeNotifyButton {
	display: flex;
	justify-content: center;
	background-image: linear-gradient(to right, var(--bg-color) 0%, var(--alt-bg-color) 51%, var(--bg-color) 100%);
    border-radius: 10px;
    border-color: var(--alt-bg-color);
    transition: 0.5s;
    background-size: 200% auto;
    height: 3.4rem;
    width: 10rem;
    font-size: 1.3rem;
	padding-top: 0.5rem;
	margin-left: 3.5rem;
	margin-top: 2rem;
}

.subscribeNotifyButton:hover{
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    border-color: var(--alt-bg-color);
    /* box-shadow: 0 0 10px var(--alt-bg-color); */
}

@media only screen and (max-width: 600px) {
	.contactus {
		height: 100vh;
	}

	.contactusHeader > h2 {
		font-size: 3rem;
	}

	.dropMeLine {
		text-align: left;
		margin: 1.5rem 1rem 0 1rem;
	}

	.dropMeLine > h2 {
		font-size: 1.4rem;
	}

	.contactusFormCard {
		height: 35rem;
		
	}

	.contactusNameInput {
		margin-bottom: 1rem;
	}

	.dropMeLine {
		margin-left: 0;
	}
}