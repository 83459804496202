.heading {
    color: #ffffff;
}

.main-text {
    margin-bottom: 0;
}

.main-text > h1{
    font-size: 6rem;
    color: var(--alt-bg-color);
    font-weight: 400;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
}

.sub {
    font-size: 1.7rem;
    font-weight: 700;
    margin-top: 15px;
    color: #838383;
}

.name-font > p {
    font-size: 1.5rem;
    font-weight: 500;
    color: rgb(40, 38, 38);
    margin-bottom: 0;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
}

.subscribeInputCol {
    display: flex;
    justify-content: center;
}

.subscribeInput{
    width: 25rem;
    margin-top: 1.5rem;
    border-radius: 10px;
}

.getStartedButton{
    background-image: linear-gradient(to right, var(--bg-color) 0%, var(--alt-bg-color) 51%, var(--bg-color) 100%);
    border-radius: 10px;
    border-color: var(--alt-bg-color);
    transition: 0.5s;
    background-size: 200% auto;
    height: 3rem;
    width: 10rem;
    margin-top: 1.4rem;
    font-size: 1.1rem;
    margin-left: 10px;
}

.getStartedButton:hover{
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    border-color: var(--alt-bg-color);
    box-shadow: 0 0 10px var(--alt-bg-color);
}

.learnMoreButton {
    border-radius: 10px;
    border-width: 0.1rem;
    border-color: var(--alt-bg-color);
    background-color: transparent;
    transition: 0.5s;
    background-size: 200% auto;
    height: 3rem;
    width: 10rem;
    margin-top: 1.4rem;
    font-size: 1.1rem;
    color: black;
    margin-left: 10px;
}

.learnMoreButton:hover{
    /* background-color: linear-gradient(to right, var(--bg-color) 0%, var(--alt-bg-color) 51%, var(--bg-color) 100%);
    background-image: linear-gradient(to right, var(--bg-color) 0%, var(--alt-bg-color) 51%, var(--bg-color) 100%);
    color: #fff; */
    background: transparent;
    color: black;
    text-decoration: none;
    border-color: var(--alt-bg-color);
    box-shadow: 0 0 10px var(--alt-bg-color);
}

.cv-button{
    z-index: 999;
    margin-top: 30px;
    padding-top: 25px !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    padding: 12px 25px !important;
    text-decoration: none !important;
    display: inline-block;
    font-size: 17px !important;
    font-weight: 600 !important;
    text-transform:uppercase !important;
    transition: all .5s ease !important;
    background-color: transparent !important;
}


.cv-button:hover{
    background-color: #ffffff !important;
    color: var(--alt-bg-color) !important;
}


@media screen and (max-width: 450px) {
    .name-font {
        /* margin-top: 2rem; */
    }

    .name-font > p{
    font-size: 1.5rem;
    }

    .sub{
        font-size: 1.4rem;
    }

    .main-text > h1 {
        font-size: 4rem;
    }
}

