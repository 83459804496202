.feature {
	margin-top: 2.5rem;
	height: 35rem;
}

.contentCenter{
	display: flex;
	justify-content: center;
}

.superHeaderRow {
	margin-bottom: 1.5rem;
}

.featureSuperHeader {
	font-size: 2rem;
	color: black;
}

.featureRow1{
	margin-top: 1rem;
}

.featureRow2{
	margin-top: 1rem;
}

.feature_card {
	padding-top: 0rem;
	border-radius: 0.5rem;
	height: 13rem;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.blackText {
	color: black;
}

.clockLineSVG {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-left: 0.5rem;
}

.cursorClickSVG {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.cursorClickSVG > img{
	width: 5rem;
	height: 5rem;
	margin-left: 1rem;
}

.clockLineSVG > img {
	width: 5rem;
	height: 5rem;
	margin-left: 0.1rem;
}

.easyClickColumn,
.easyClickColumnContent {
	margin-left: 1rem;
	padding-right: 2rem;
}

.clockLineColumn,
.clockLineColumnContent {
	margin-left: 1rem;
	padding-right: 0.5rem;
}

.clockLineColumnContent {
	padding-right: 0.75rem;
}

.clockLineColumn > h2,
.easyClickColumn > h2 {
	/* margin-left: 0; */
	/* margin-top: 0.75rem; */
	font-size: 1.5rem;
	color: var(--alt-bg-color);
}

.clockLineColumnContent > p,
.easyClickColumnContent > p {
	line-height: 1.75rem;
	font-size: 1.1rem;
	color: black;
}

@media only screen and (max-width: 600px) {
	.feature {
		margin-top: 4rem;
		height: 62rem;
	}
	
	.featureSuperHeader {
		text-align: center;
	}

	.featureRow2_col2_card {
		margin-top: 1rem;
		height: 15rem;
	}

	.featureRow1_col2_card{
		margin-top: 1rem;
	}

	.clockLineSVG,
	.cursorClickSVG {
		padding-left: 0.5rem;
	}

	.clockLineSVG > img,
	.cursorClickSVG > img {
		width: 3rem;
		height: 3rem;
		margin-left: 0.2rem;
	}

	.card_body {
		padding-top: 0.25rem;
	}

	.clockLineColumn,
	.clockLineColumnContent,
	.easyClickColumnContent,
	.easyClickColumn {
		margin-left: 0.6rem;
		padding-right: 0.2rem;
	}

	.clockLineColumn > h2,
	.easyClickColumn > h2 {
		font-size: 1.3rem;
		margin-top: 0.5rem;
	}

	.clockLineColumnContent > p,
	.easyClickColumnContent > p {
		line-height: 1.25rem;
		font-size: 1.07rem;
	}

	.card_col {
		padding: 0;
	}
}