.bglanding{
    /* background-color: var(--alt-bg-color); */
    /* height: 100vh;
    width: 220vh; */
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 35%, rgba(245,213,242,1) 79%, rgba(229,184,215,1) 100%);
    user-select: none;
    height: 100vh;
}

.bgimage{
    /* max-height: 710px;
    max-width: 1550px; */
    user-select: none;
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .bglanding {
        height: 100vh;
    }
}